import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import * as Yup from 'yup'
import { userActions } from '../_actions/user.actions'
import { userConstants } from '../_constants'
import { TrackingService } from '../_services/tracking.service.js'
import { CoachingExp } from './CoachingExp'
import './multistep.css'
import { PersionalInfo } from './PersonalInfo'
import { PrefessionalExp } from './ProfessionalExp'

function onKeyDown(keyEvent: any) {
    if (
        keyEvent.target.id !== 'bio' &&
        (keyEvent.charCode || keyEvent.keyCode) === 13
    ) {
        keyEvent.preventDefault()
    }
}

const renderStep = (
    step: any,
    props: any,
    statics: Statics,
    coach: any,
    dispatch: any,
    errors: any,
    touched: any,
    profilePic: any,
) => {
    if (profilePic) {
        props.values.profilePic = profilePic
    }
    switch (step) {
        case 1:
            return (
                <PersionalInfo
                    {...props}
                    statics={statics}
                    coach={coach}
                    dispatch={dispatch}
                    errors={errors}
                    touched={touched}
                />
            )
        case 2:
            return (
                <CoachingExp
                    {...props}
                    statics={statics}
                    coach={coach}
                    errors={errors}
                    touched={touched}
                />
            )
        case 3:
            return (
                <PrefessionalExp
                    {...props}
                    statics={statics}
                    coach={coach}
                    errors={errors}
                    touched={touched}
                />
            )
        default:
            return (
                <PersionalInfo
                    {...props}
                    statics={statics}
                    coach={coach}
                    dispatch={dispatch}
                    errors={errors}
                    touched={touched}
                />
            )
    }
}
function MultiStepForm(props: any) {
    const { coach, dispatch } = props
    const { statics } = props.statics
    const [step, setStep] = useState(1)
    const [open, setOpen] = useState(false)
    const [profilePic, setProfilePic] = useState<any>()
    const [editIndividual, setEditIndividual] = useState<any>(false)
    const location = useLocation()
    const navigate = useNavigate()

    const closeModal = () => setOpen(false)

    useEffect(() => {
        if (location?.search) {
            const query = new URLSearchParams(location.search)
            const queryStep = query.get('fromProfile')
            const pic = localStorage.getItem('picture')
            if (pic) {
                setProfilePic(pic)
            }
            if (queryStep) {
                setEditIndividual(queryStep)
                setStep(parseInt(queryStep))
            }
        } else if (sessionStorage.getItem('count')) {
            setStep(Number(sessionStorage.getItem('count')))
            sessionStorage.removeItem('count')
        } else if (location?.state?.step) {
            setStep(location?.state?.step)
        }
    }, [])

    const gotoNext = (values: any) => {
        if (values) {
            TrackingService.send('onboarding', {
                event: 'continue',
                step: step,
            })
            props.dispatch(userActions.updateProfile(values))

            if (editIndividual) {
                navigate('/profile')
            } else {
                if (step < 3) {
                    setStep((step) => step + 1)
                }

                if (step == 3) {
                    props.dispatch({
                        type: userConstants.GET_PICTURE,
                        payload: localStorage.getItem('picture'),
                    })
                    navigate('/home')
                }
            }
        }
    }

    const ValidationSchemaStep1 = Yup.object().shape({
        profilePic: Yup.string().nullable().required('Required'),
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        birthday: Yup.date().required('Required'),
        phoneNumber: Yup.string()
            .matches(/[0-9]{1,15}/, {
                message: 'Should be no more than 15 numbers',
            })
            .required('Required'),
        gender: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        city: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        nativeLanguages: Yup.array()
            .min(1, 'must select at least one native language')
            .required('Required'),
        coachingLanguages: Yup.array()
            .min(1, 'must select at least one coaching language')
            .required('Required'),
        highestEducationLevel: Yup.string().required('Required'),
        maximumHourPerWeek: Yup.number()
            .max(40, 'must be less than or equal to 40')
            .min(1, 'must be great than or equal to 1')
            .required('Required'),
    })

    // Coaching experince validations
    const ValidationSchemaStep2 = Yup.object().shape({
        bio: Yup.string()
            .max(500, 'must be less than or equal to 500 characters')
            .min(10, 'must be great than or equal to 10 characters')
            .required('Required'),
        startCoachingYear: Yup.number()
            .max(new Date().getFullYear(), 'must be this year or before')
            .min(1970, 'must be after 1970')
            .required('Required'),
        areasCompetencies: Yup.array()
            .min(1, 'must select at least one competency')
            .required('Required'),
        coachingSpecialitiesTopics: Yup.array().optional(),
        coachingSpecialitiesClient: Yup.array().optional(),
        coachingExperience: Yup.string().required('Coaching Experience is required'),
        coachingCredentials: Yup.string().required('Coaching Credential is required'),
        coachingCertifications: Yup.array().optional(),
        coachingMentoring: Yup.array().optional(),
        trainingTopics: Yup.array().optional(),
        collaborationInterest: Yup.string().nullable().optional(),
        coachCollaborationAreaOfInterest: Yup.string().nullable().optional(),
        coachingAccreditations: Yup.array()
            .min(1, 'must select at least one accredication')
            .required('Required'),
        coachingMemberships: Yup.array()
            .min(1, 'must select at least one coaching memebership')
            .required('Required'),
        coachingIndustries: Yup.array()
            .min(1, 'must select at least one coaching industry')
            .required('Required'),
        coachingFunctions: Yup.array()
            .min(1, 'must select at least one coaching function')
            .required('Required'),
    })

    // professional experience validations
    const ValidationSchemaStep3 = Yup.object().shape({
        experienceTotalYear: Yup.number()
            .min(1, 'minimun value 1')
            .max(60, 'maximum value 70')
            .required('Required'),
        profIndexp: Yup.array()
            .min(1, 'must select at least one industry')
            .required('Required'),
        profFunexp: Yup.array()
            .min(1, 'must select at least one functional experience')
            .required('Required'),
        experienceCountry: Yup.array()
            .min(1, 'must select at least one country worked in')
            .required('Required'),
    })

    const saveForLater = (v: any) => {
        TrackingService.send('onboarding', {
            event: 'save_for_later',
            step: step,
        })

        props.dispatch(userActions.updateProfile(v))
        setOpen(true)
    }
    const getCoachExperienceYearsInArray = () => {
        let templateData = [
            {
                position: 'executive',
                years: 0,
            },
            {
                position: 'senior',
                years: 0,
            },
            {
                position: 'manager',
                years: 0,
            },
            {
                position: 'associates',
                years: 0,
            },
            {
                position: 'admin',
                years: 0,
            },
        ]
        templateData.forEach((data) => {
            coach?.experienceYearIn.forEach((year: any) => {
                if (data.position == year.position) {
                    data.years = year.years
                }
            })
        })

        return templateData
    }

    const getTopMenuClasses = (currentStep: any, amIStep: any) => {
        let classNames = ''
        classNames = classNames + (currentStep === amIStep ? 'selected' : '')
        classNames = classNames + (amIStep < currentStep ? 'clickable' : '')
        return classNames
    }

    const topMenuOnClick = (currentStep: any, amIStep: any) => {
        if (amIStep < currentStep) {
            setStep(amIStep)
        }
    }

    const getFromValidationForStep = (currentStep: any) => {
        if (currentStep === 1) {
            return ValidationSchemaStep1
        } else if (currentStep === 2) {
            return ValidationSchemaStep2
        } else if (currentStep === 3) {
            return ValidationSchemaStep3
        }
    }

    //const coach = ('user' in user) ? JSON.parse(JSON.stringify(user.user)) : {};
    return (
        <div className="height-vh bg-pink">
            <div className="container-fluid inner-header">
                <div className="brand pull-left">
                    <img
                        src="assets/Logo1@2x.png"
                        width="130"
                        alt="logo"
                        className="logo"
                    />
                </div>
            </div>
            <div className="profile-tab-block">
                <div className="profile-tab-list">
                    {!editIndividual && (
                        <ul className="nav nav-justified">
                            <li>
                                <span
                                    className={getTopMenuClasses(step, 1)}
                                    onClick={() => topMenuOnClick(step, 1)}
                                >
                                    1. Personal information
                                </span>
                            </li>
                            <li>
                                <span
                                    className={getTopMenuClasses(step, 2)}
                                    onClick={() => topMenuOnClick(step, 2)}
                                >
                                    2. Coaching experience
                                </span>
                            </li>
                            <li>
                                <span
                                    className={getTopMenuClasses(step, 3)}
                                    onClick={() => topMenuOnClick(step, 3)}
                                >
                                    3. Professional experience
                                </span>
                            </li>
                        </ul>
                    )}
                </div>
                <div className="profile-tab-container">
                    <Formik
                        initialValues={{
                            profilePic: coach?.profilePicture,
                            firstName: coach?.user.firstName,
                            lastName: coach?.user.lastName,
                            birthday: coach?.user.birthday,
                            phoneNumber: coach?.user.phoneNumber,
                            gender: coach?.gender,
                            country: props?.statics?.selectedCountry,
                            city: coach?.user?.address?.city,
                            nativeLanguages: props?.statics?.selectedLangs,
                            coachingLanguages:
                                props?.statics?.selectedCoachLangs,
                            highestEducationLevel:
                                props?.statics?.highestEducationLevel,
                            maximumHourPerWeek: coach?.maximumHourPerWeek,
                            bio: coach?.bio,
                            startCoachingYear: coach?.startCoachingYear,
                            areasCompetencies:
                                props?.statics?.areasCompetencies,
                            coachingSpecialitiesTopics:props?.statics.coachingSpecialityTopics,
                            coachingSpecialitiesClient: props?.statics.coachingSpecialitiesClients,
                            coachingExperience: props?.statics?.coachingExperience,
                            coachingCredentials: props?.statics?.coachingCredentials,
                            coachingCertifications: props?.statics?.coachingCertifications,
                            collaborationInterest: null,
                            coachCollaborationAreaOfInterest: coach?.profileInformation?.coachCollaborationAreaOfInterest ?? '',
                            coachingMentoring: props?.statics?.coachingMentorings,
                            trainingTopics: props?.statics.coachTrainingTopics,                            
                            coachingAccreditations:
                                props?.statics?.coachingAccreditations,
                            coachingMemberships:
                                props?.statics?.coachingMemberships,
                            coachingIndustries:
                                props?.statics?.coachingIndustries,
                            coachingFunctions:
                                props?.statics?.coachingFunctions,
                            experienceYearIn: getCoachExperienceYearsInArray(),
                            experienceTotalYear: coach?.experienceTotalYear,
                            profIndexp: props?.statics?.experienceIndustries,
                            profFunexp: props?.statics?.experienceFunctions,
                            experienceCountry:
                                props?.statics?.experienceCountry,
                        }}
                        validationSchema={getFromValidationForStep(step)}
                        onSubmit={(values, actions) => {
                            gotoNext(values)
                        }}
                    >
                        {(props) => {
                            const {
                                touched,
                                errors,
                                dirty,
                                isValid,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                validateForm,
                            } = props
                            return (
                                <div>
                                    <form
                                        onKeyDown={onKeyDown}
                                        onSubmit={handleSubmit}
                                    >
                                        {renderStep(
                                            step,
                                            props,
                                            statics,
                                            coach,
                                            dispatch,
                                            errors,
                                            touched,
                                            profilePic,
                                        )}
                                        <div className="form-group btn-block-bottom btns-left-right">
                                            {/*<button className="relative btn btn-primary btn-bolck" type="button" onClick={gotoPrev}>Previous</button>*/}
                                            {!editIndividual && (
                                                <button
                                                    className="relative btn btn-primary btn-bolck"
                                                    type="submit"
                                                >
                                                    Continue
                                                </button>
                                            )}
                                            {!editIndividual && (
                                                <button
                                                    className="relative btn btn-light btn-bolck"
                                                    type="button"
                                                    onClick={() =>
                                                        saveForLater(
                                                            props?.values,
                                                        )
                                                    }
                                                >
                                                    Save for later
                                                </button>
                                            )}
                                            {editIndividual && (
                                                <button
                                                    className="relative btn btn-primary btn-bolck"
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                            )}
                                            <Popup
                                                open={open}
                                                onClose={closeModal}
                                                className="alertBox"
                                                modal={true}
                                                nested
                                            >
                                                <div
                                                    className="modal modal-lg modal-center"
                                                    id="success-popup-modal"
                                                >
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                                <p>
                                                                    Your
                                                                    information
                                                                    has been
                                                                    successfull
                                                                    saved
                                                                </p>
                                                                <span className="success-icon fa fa-check-circle"></span>
                                                            </div>
                                                            <div className="modal-footer justify-content-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    onClick={
                                                                        closeModal
                                                                    }
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popup>
                                        </div>
                                    </form>
                                </div>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
    statics: state.statics,
})
const actionCreators = {
    updateProfile: userActions.updateProfile,
}

export default connect(mapStateToProps)(MultiStepForm)
